.u-pc_show {
  display: block;
  @media #{$breakpoint} {
    display: none;
  }
}

.u-sp_show {
  display: none;
  @media #{$breakpoint} {
    display: block;
  }
}

.is-coming_soon {
  pointer-events: none;
}

.noscroll {
  overflow: hidden !important;
  height: 100vh;
}

@for $i from 0 through 50 {
  .u-mt_#{4 * $i} {
    margin-top: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-ml_#{4 * $i} {
    margin-left: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-mb_#{4 * $i} {
    margin-bottom: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-pt_#{4 * $i} {
    padding-top: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-pl_#{4 * $i} {
    padding-left: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-pb_#{4 * $i} {
    padding-bottom: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-pr_#{4 * $i} {
    padding-right: #{.4 * $i}rem;
  }
}

@for $i from 0 through 50 {
  .u-sp_mt_#{4 * $i} {
    @media #{$breakpoint} {
      margin-top: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_ml_#{4 * $i} {
    @media #{$breakpoint} {
      margin-left: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_mb_#{4 * $i} {
    @media #{$breakpoint} {
      margin-bottom: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pt_#{4 * $i} {
    @media #{$breakpoint} {
      padding-top: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pl_#{4 * $i} {
    @media #{$breakpoint} {
      padding-left: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pb_#{4 * $i} {
    @media #{$breakpoint} {
      padding-bottom: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pr_#{4 * $i} {
    @media #{$breakpoint} {
      padding-right: #{.4 * $i}rem;
    }
  }
}
