@charset "UTF-8";

// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　PATH
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

// usage: #{$imgPath}
$imgPath: "../img/";

// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　COLOR
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
$fontColor: #000;
$mainColor: #000;
$keyColor: #b59a3f;
$bgColor: #191919;
$footerFont: #191508;
$boderColor: #595959;

// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　FONT
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

$fontsize: 1.6rem;
$fontsizeSp: 1.4rem;

$fontDM: 'DM Serif Text', sans-serif;
$fontNoto: 'Noto Sans JP', sans-serif;


// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
//　　Media Query
// ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━

// $columnwidth__01: 1240px;
// $columnwidth__02: 1020px;

$wraper: 100%;

$breakpoint: "screen and (max-width: 48em)";
$headerBreakpoint: "screen and (max-width: 63.75em)";
$ie11: "all and (-ms-high-contrast:none)";
$iphone5: "screen and (max-width: 20em)";
