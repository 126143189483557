.c-alt {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.c-ttl_large {
  font-size: 9.9rem;
  font-family: $fontDM;
  text-align: center;
  color: #fff;
  @media #{$breakpoint} {
    font-size: 7rem;
  }
  &_sub {
    display: block;
    font-size: 1.8rem;
    font-family: $fontNoto;
    font-weight: 700;
    @media #{$breakpoint} {
      font-size: 1.6rem;
    }
  }
}
.c-ttl_medium {
  font-size: 9rem;
  font-family: $fontDM;
  color: $keyColor;
  @media #{$breakpoint} {
    font-size: 7rem;
  }
  &_sub {
    display: block;
    font-family: $fontNoto;
    font-size: 1.8rem;
    font-weight: 700;
    letter-spacing: 0.06em;
    @media #{$breakpoint} {
      font-size: 1.6rem;
    }
  }
}
.c-txt_wrapping {
  display: inline-block;
  line-height: 1.18;
  transform: translateY(100%);
  &_wrapper {
    display: inline-block;
    overflow: hidden;
  }
}
